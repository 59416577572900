<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import Banners from "@/components/widgets/banners";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
      Dashboard: "Painel",
      "Referral link": "Link de indicação",
      "My Boards": "Meus Tabuleiros",
      "My Sponsored": "Meus Indicados",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
      "To activate your indication link you need to be active in a board.": "Para ativar o seu link de indicação você precisa estar ativo em um tabuleiro.",
    },
    es: {
      Dashboard: "Panel",
      "Referral link": "Link de patrocineo",
      "My Boards": "Mis Tableros",
      "My Sponsored": "Meus Indicados",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
      "To activate your indication link you need to be active in a board.": "Para activar su enlace de indicación, debe estar activo en una placa.",
    },
  },
  components: {
    Layout,
    Banners,
    Stat,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    monitorWidth: function() {
      var width = screen.width;
      return width;
    }
  },
  data() {
    return {
      informatives: {
        modal: false,
        list: null,
      },

      referrallink: null,

      total: {
        sponsored: "0",
        donations: "R$  -,--",
      },

      monthly: "inactive",

      statData: null,

      donations: {
        pendings: null,
      }
    };
  },
  methods: {
    getInformatives() {
      api.get("informatives").then((response) => {
        if (response.data.status == "success") {
          this.informatives.list = response.data.list;

          if (this.informatives.list.length > 0 && this.informatives.list[0]) {
            this.informatives.modal = true;
          }
        }
      });
    },
    getReferralLink() {
      api.get("user/referrallink").then((response) => {
        this.referrallink = response.data.status;
      });
    },
    getTotalSponsored() {
      api.get("boards/total/sponsored").then((response) => {
        if (response.data.status == "success") {
          this.total.sponsored = response.data.total.toString();
          this.getStatData();
        }
      });
    },
    getTotalDonation() {
      api.get("boards/total/donations").then((response) => {
        if (response.data.status == "success") {
          this.total.donations = this.$options.filters.currency(
            response.data.total
          );
          this.getStatData();
        }
      });
    },
    getStatData() {
      this.statData = [
        {
          title: "Username",
          value: this.account.user.username,
        },
        {
          title: "Sponsored",
          value: this.total.sponsored,
        },
        {
          title: "Donation Received",
          value: this.total.donations,
        },
      ];
    },
    getDonationsPendings() {
      api.get("boards/donations/pendings").then((response) => {
        if (response.data.status == "success") {
          this.donations.pendings = response.data.list;
        }
      });
    },
  },
  mounted() {
    this.getInformatives();
    this.getReferralLink();
    this.getTotalSponsored();
    this.getTotalDonation();
    this.getStatData();
    this.getDonationsPendings();
  },
};
</script>

<template>
  <Layout>
    <b-modal v-if="informatives.list && informatives.list[0]" v-model="informatives.modal" :title="informatives.list[0].title" centered>
      <template v-if="monitorWidth < 600">
        <div v-if="informatives.list[0].image.mobile" class="mb-3">
          <template v-if="informatives.list[0].url">
            <a target="_blank" :href="informatives.list[0].url"><img class="w-100 rounded" :src="informatives.list[0].image.mobile" :alt="informatives.list[0].title" /></a>
          </template>
          <template v-else>
            <img class="w-100 rounded" :src="informatives.list[0].image.mobile" :alt="informatives.list[0].title" />
          </template>
        </div>
      </template>
      <template v-else>
        <div v-if="informatives.list[0].image.desktop" class="mb-3">
          <template v-if="informatives.list[0].url">
            <a target="_blank" :href="informatives.list[0].url"><img class="w-100 rounded" :src="informatives.list[0].image.desktop" :alt="informatives.list[0].title" /></a>
          </template>
          <template v-else>
            <img class="w-100 rounded" :src="informatives.list[0].image.desktop" :alt="informatives.list[0].title" />
          </template>
        </div>
      </template>
      <div v-html="informatives.list[0].content"></div>
      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>

    <Banners></Banners>

    <div
      v-if="referrallink && referrallink == 'inactive'"
      class="bg-soft-danger p-3 mb-3 rounded d-flex align-items-center"
    >
      <i class="lnr lnr-warning" style="font-size: 30px"></i>
      <div class="px-3 flex-fill">
        <h6 class="m-0 font-size-15 pt-1">
          {{ t("To activate your indication link you need to be active in a board.") }}
        </h6>
      </div>
    </div>

    <template v-if="donations.pendings && donations.pendings.length > 0">
      <div v-for="(row, index) in donations.pendings" :key="index">
        <template v-if="row.receiver && row.receiver.status == 'pending'">
          <router-link class="bg-soft-info p-3 mb-3 rounded d-flex align-items-center" tag="a" :to="'/boards/position/' + row.id">
            <i class="lnr lnr-star text-dark" style="font-size: 30px"></i>
            <div class="px-3 flex-fill pt-1">
              <h6 class="m-0 font-size-15 text-">
                Faça a doação para <strong>"{{ row.receiver.username }}"</strong> no valor de <strong>{{ row.value | currency }}</strong> em seu tabuleiro <strong>{{ row.type }}</strong>.
              </h6>
            </div>
          </router-link>
        </template>
      </div>
    </template>

    <div class="card">
      <div class="card-body py-2 d-flex align-items-center">
        <div class="mt-1">
          <i class="bx bx-link font-size-20 mr-1"></i>
        </div>
        <h5 class="m-0 align-items-center">
          {{ t("Referral link") }}
        </h5>
        <div class="mt-1 ml-3">
          <ShareNetwork
            network="whatsapp"
            url=""
            :title="'https://brothersoflegacy.app/' + account.user.username"
          >
            <i class="bx bxl-whatsapp font-size-24 text-dark p-0"></i>
          </ShareNetwork>
        </div>
        <div class="mt-1 ml-2">
          <button
            class="btn btn-link p-0 m-0"
            v-clipboard:copy="
              'https://brothersoflegacy.app/' + account.user.username
            "
            v-on:click="$noty.success('O link foi copiado!')"
          >
            <i class="bx bx-copy font-size-20 text-dark"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div
        v-for="stat of statData"
        :key="stat.icon"
        class="col-md-4 col-lg-4 col-xl-4"
      >
        <Stat
          :title="stat.title"
          :value="stat.value"
        />
      </div>
    </div>

    <div class="d-block d-sm-none">
      <router-link class="btn btn-default btn-block btn-lg py-3 d-flex align-items-center justify-content-center mb-3" tag="a" to="/boards">
        <i class="lnr lnr-layers font-size-24 mr-2"></i>
        <div>{{ t("My Boards") }}</div>
      </router-link>
      <router-link class="btn btn-default btn-block btn-lg py-3 d-flex align-items-center justify-content-center mb-3" tag="a" to="/network/sponsored">
        <i class="lnr lnr-user font-size-24 mr-2"></i>
        {{ t("My Sponsored") }}
      </router-link>
    </div>
  </Layout>
</template>
